@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.tool-bar[data-v-58b5bd6c] {
  overflow: hidden;
}
.tool-bar > .item[data-v-58b5bd6c] {
  position: relative;
}
.tool-bar > .item > .price[data-v-58b5bd6c] {
  font-size: 120%;
  font-weight: bold;
}
.tool-bar > .item > .badge[data-v-58b5bd6c] {
  position: absolute;
  right: -0.2rem;
  top: -0.2rem;
  border-radius: 0;
  font-size: 75%;
  padding: 0.2rem 0.6rem 0.05rem 0.6rem;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: translate(50%, 0) rotate(45deg);
          transform: translate(50%, 0) rotate(45deg);
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.achievement-wrap {
  padding: 0.3rem;
  background-image: -webkit-linear-gradient(290deg, #fff -300%, #f90 150%);
  background-image: -webkit-linear-gradient(290deg, #fff -300%, var(--color-second, #f90) 150%);
  background-image: linear-gradient(160deg, #fff -300%, #f90 150%);
  background-image: linear-gradient(160deg, #fff -300%, var(--color-second, #f90) 150%);
}
.achievement-wrap > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  margin-bottom: 0.3rem;
}
.achievement-wrap > .header > .label,
.achievement-wrap > .header > .extra {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  font-size: 120%;
  font-weight: bold;
}
.achievement-wrap > .header > .extra {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}
.achievement-wrap > .body {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.achievement-wrap > .body > .item {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  color: #fff;
  padding-right: 0.3rem;
}
.achievement-wrap > .body > .item:after {
  position: absolute;
  top: 50%;
  right: 0.2rem;
  content: "";
  display: block;
  height: 6.667vw;
  width: 1px;
  margin-top: -3.333vw;
  opacity: 0.5;
  background-color: #f1f1f1;
}
.achievement-wrap > .body > .item:last-child {
  padding-right: 0;
}
.achievement-wrap > .body > .item:last-child:after {
  display: none;
}
.achievement-wrap > .body > .item > .label {
  opacity: 0.9;
}
.achievement-wrap > .body > .item > .value {
  font-size: 120%;
  font-weight: bold;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.settlement-item {
  background-color: #fff;
  padding: 0.2rem;
}
.settlement-item + .settlement-item {
  margin-top: 0.2rem;
}
.settlement-item > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 90%;
  color: #999;
}
.settlement-item > .body {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.money-box[data-v-19d1df5d] {
  background-image: -webkit-linear-gradient(290deg, #fff -500%, #e52779 70%);
  background-image: -webkit-linear-gradient(290deg, #fff -500%, var(--color-main, #e52779) 70%);
  background-image: linear-gradient(160deg, #fff -500%, #e52779 70%);
  background-image: linear-gradient(160deg, #fff -500%, var(--color-main, #e52779) 70%);
}
.money-box > .money[data-v-19d1df5d],
.money-box > .label[data-v-19d1df5d] {
  color: #fff;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.upload.kf-qrcode > .item > .inner {
  padding-top: 60%;
  padding-bottom: 60%;
}
.upload.kf-qrcode > .item.add > .inner > .f-icon {
  font-size: 130%;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.store-banner > .banner {
  position: relative;
  width: 100%;
}
.store-banner > .banner > .bg {
  width: 100%;
  padding-top: 30%;
  background: #e9ecef center no-repeat;
  background-size: cover;
  position: relative;
}
.store-banner > .banner > .bg:before {
  content: "";
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  border: 0.4vw dotted #fff;
  opacity: 0.5;
}
.store-banner > .banner > .bg:after {
  content: "\66F4\6539";
  position: absolute;
  bottom: 0.2rem;
  right: 0.3rem;
  color: #fff;
}
.store-banner > .banner > .customer-service-image {
  position: absolute;
  z-index: 200;
  right: 0.1rem;
  top: 0.1rem;
}
.store-banner .store-name > input {
  outline: none;
  height: 100%;
  width: 100%;
  background-color: transparent;
}
.store-banner.type1 {
  background-color: #fff;
  padding-bottom: 0.3rem;
}
.store-banner.type1 > .inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0 0.3rem;
  margin-top: -0.8rem;
  pointer-events: none;
}
.store-banner.type1 > .inner > .logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.1rem;
  margin-right: 0.2rem;
  background: #dee2e6 center no-repeat;
  background-size: cover;
  pointer-events: auto;
  position: relative;
}
.store-banner.type1 > .inner > .logo:before {
  content: "";
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  border: 0.4vw dotted #fff;
  opacity: 0.5;
}
.store-banner.type1 > .inner > .logo:after {
  content: "\66F4\6539";
  position: absolute;
  bottom: 0.2rem;
  right: 0.3rem;
  color: #fff;
}
.store-banner.type1 > .inner > .info {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.store-banner.type1 > .inner > .info > .store-name {
  font-size: 120%;
  font-weight: bold;
  line-height: 0.6rem;
  pointer-events: auto;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.store-banner.type1 > .inner > .info > .customer-service {
  line-height: 0.6rem;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.store-banner.type2 {
  background-color: #fff;
  padding-bottom: 0.3rem;
}
.store-banner.type2 > .banner {
  position: relative;
}
.store-banner.type2 > .banner > .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 0.3rem;
}
.store-banner.type2 > .banner > .inner > .logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.1rem;
  margin-right: 0.2rem;
  background: #dee2e6 center no-repeat;
  background-size: cover;
  position: relative;
}
.store-banner.type2 > .banner > .inner > .logo:before {
  content: "";
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  border: 0.4vw dotted #fff;
  opacity: 0.5;
}
.store-banner.type2 > .banner > .inner > .logo:after {
  content: "\66F4\6539";
  position: absolute;
  bottom: 0.2rem;
  right: 0.3rem;
  color: #fff;
}
.store-banner.type2 > .banner > .inner > .info {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.store-banner.type2 > .banner > .inner > .info > .store-name {
  font-size: 120%;
  font-weight: bold;
  line-height: 0.6rem;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  background-color: rgba(255, 255, 255, 0.9);
  display: inline-block;
  border-radius: 100vw;
  padding: 0 0.2rem;
}
.store-banner.type2 > .banner > .inner > .info > .customer-service {
  line-height: 0.6rem;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.store-banner.type3 {
  background-color: #fff;
  padding-bottom: 0.3rem;
}
.store-banner.type3 > .banner {
  position: relative;
}
.store-banner.type3 > .banner > .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 0.3rem;
}
.store-banner.type3 > .banner > .inner > .logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.1rem;
  background: #dee2e6 center no-repeat;
  background-size: cover;
  position: relative;
}
.store-banner.type3 > .banner > .inner > .logo:before {
  content: "";
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  left: 0.1rem;
  border: 0.4vw dotted #fff;
  opacity: 0.5;
}
.store-banner.type3 > .banner > .inner > .logo:after {
  content: "\66F4\6539";
  position: absolute;
  bottom: 0.2rem;
  right: 0.3rem;
  color: #fff;
}
.store-banner.type3 > .banner > .inner > .info {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.store-banner.type3 > .banner > .inner > .info > .store-name {
  font-size: 110%;
  font-weight: bold;
  line-height: 0.6rem;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  background-color: rgba(255, 255, 255, 0.9);
  display: inline-block;
  border-radius: 100vw;
  padding: 0 0.2rem;
}
.store-banner.type3 > .banner > .inner > .info > .customer-service {
  line-height: 0.6rem;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.goods-list-skeleton.type1 > .item + .item {
  margin-top: 0.2rem;
}
.goods-list-skeleton.type1 > .item > .inner {
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  background-color: #fff;
}
.goods-list-skeleton.type1 > .item > .inner > .img-obj {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 2rem;
  margin-right: 0.1rem;
}
.goods-list-skeleton.type1 > .item > .inner > .img-obj > .inner {
  width: 100%;
  padding-top: 100%;
  background-color: #e9ecef;
}
.goods-list-skeleton.type1 > .item > .inner > .inner {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.goods-list-skeleton.type1 > .item > .inner > .inner > .price_,
.goods-list-skeleton.type1 > .item > .inner > .inner > .desc,
.goods-list-skeleton.type1 > .item > .inner > .inner > .label {
  background-color: #e9ecef;
  margin-top: 0.1rem;
}
.goods-list-skeleton.type1 > .item > .inner > .inner > .label {
  margin-top: 0;
}
.goods-list-skeleton.type1 > .item > .inner > .inner > .desc {
  width: 80%;
}
.goods-list-skeleton.type1 > .item > .inner > .inner > .price_ {
  width: 50%;
}
.goods-list-skeleton.type2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.1rem;
}
.goods-list-skeleton.type2 > .item {
  width: 50%;
  padding: 0.1rem;
}
.goods-list-skeleton.type2 > .item + .item {
  margin-top: 0;
}
.goods-list-skeleton.type2 > .item > .inner {
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  background-color: #fff;
}
.goods-list-skeleton.type2 > .item > .inner > .img-obj {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}
.goods-list-skeleton.type2 > .item > .inner > .img-obj > .inner {
  width: 100%;
  padding-top: 100%;
  background-color: #e9ecef;
}
.goods-list-skeleton.type2 > .item > .inner > .inner {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
}
.goods-list-skeleton.type2 > .item > .inner > .inner > .price_,
.goods-list-skeleton.type2 > .item > .inner > .inner > .desc,
.goods-list-skeleton.type2 > .item > .inner > .inner > .label {
  background-color: #e9ecef;
  margin-top: 0.1rem;
}
.goods-list-skeleton.type2 > .item > .inner > .inner > .desc {
  width: 80%;
}
.goods-list-skeleton.type2 > .item > .inner > .inner > .price_ {
  width: 50%;
}
.cat-list-skeleton.type1 > .cat-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0.2rem;
  background-color: #fff;
}
.cat-list-skeleton.type1 > .cat-list > .item {
  width: 2rem;
  background-color: #e9ecef;
}
.cat-list-skeleton.type1 > .cat-list > .item + .item {
  margin-left: 0.2rem;
}
.cat-list-skeleton.type2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.cat-list-skeleton.type2 > .cat-list {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 0.2rem;
  background-color: #fff;
}
.cat-list-skeleton.type2 > .cat-list > .item {
  width: 1.2rem;
  background-color: #e9ecef;
}
.cat-list-skeleton.type2 > .cat-list > .item + .item {
  margin-top: 0.2rem;
}
.cat-list-skeleton.type2 > .goods-list-skeleton {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
}

/*# sourceMappingURL=proxyStore.869a62e5.css.map*/